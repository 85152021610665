


import { Heading, HeadingProps, useBreakpointValue } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface Props extends HeadingProps {
  
}

const Header = ({ children, ...props }: PropsWithChildren<Props>) => (
    <Heading fontSize={useBreakpointValue({ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' })} {...props}>
      {children}
    </Heading>
  );
  export default Header;