
import { useState } from 'react';
import {
  useClipboard,
  useToast,
  IconButton
} from '@chakra-ui/react';

import { FiMail } from 'react-icons/fi'

function CopyEmail() {
  const [value] = useState("hello@lesliecallum.com")
  const { onCopy } = useClipboard(value)
  const toast = useToast();
  return (
    <>
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={`Email`}
        variant="ghost"
        color="current"
        marginLeft="2"
        icon={<FiMail strokeWidth="1.4" />}
        onClick={() => {
          onCopy();
          toast({
            title: "Email copied to clipboard!",
            // description: value,
            status: "success",
            duration: 3000,
            isClosable: true
          });
        }
        }
      />
    </>
  )
}

export default CopyEmail;