import { useColorModeValue, Box, BoxProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface Props extends BoxProps {
}

const CustomBox = ({ children, ...props }: PropsWithChildren<Props>) => (
  <Box borderColor={useColorModeValue('gray.400', 'gray.700')} {...props} >
    {children}
  </Box>
)
export default CustomBox;