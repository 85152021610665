import { useBreakpointValue, Tabs, TabsProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface Props extends TabsProps {
  
}

const CustomTabs = ({ children, ...props }: PropsWithChildren<Props>) => (
  <Tabs {...props} size={useBreakpointValue({ base: "sm", md: "md", lg: "md" })} isFitted variant="enclosed" fontWeight="normal">
    {children}
  </Tabs>
)
export default CustomTabs;