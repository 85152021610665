import Prismic from '@prismicio/client'

const apiEndpoint = 'https://callum.cdn.prismic.io/api/v2'
const accessToken = 'MC5ZTjNNWHhJQUFDTUFBbEdi.77-977-9PO-_vTnvv70y77-9bO-_ve-_ve-_vScdR--_ve-_ve-_ve-_ve-_vQ7vv73vv70I77-977-9G--_ve-_vQLvv73vv70'

export const client = Prismic.client(apiEndpoint, { accessToken })

export const linkResolver = (doc) => {
    // URL for a category type
    if (doc.type === 'blog') {
      return `/blog/${doc.uid}`
    }
    // Backup for all other types
    return '/'
}