import { useBreakpointValue, useColorModeValue, Button, ButtonProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface Props extends ButtonProps {
}

const Buttons = ({ children, ...props }: PropsWithChildren<Props>) => (
  <Button bg="whiteAlpha.50" borderColor={useColorModeValue('gray.400', 'gray.700')} p={4} variant = "ghost" size={useBreakpointValue({ base: "xs", md: "md", lg: "md" })} {...props} textStyle="mono">
    {children}
  </Button>
)
export default Buttons;