import { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface IPageProps extends RouteProps {
  title: string;
}

const Page: FunctionComponent<IPageProps> = props => {
  useEffect(() => {
    document.title = "Callum Lim - " + props.title;
  });

  const { title, ...rest } = props;
  return <Route {...rest} />;
};

export default Page;