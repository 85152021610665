import {
  Box,
  Text,
  Stack,
  Heading,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  GridItem,
  Grid,
  Code,
} from '@chakra-ui/react';

import Prismic from '@prismicio/client'
import { Date, RichText } from 'prismic-reactjs'
import { useState, useEffect } from 'react';
import CustomBox from '../themes/customBox';
import CustomDivider from '../themes/customDivider';
import { client } from '../prismic-configuration';
import { CodeBlock, atomOneDark } from "react-code-blocks";
import { Link, RouteComponentProps } from "react-router-dom";

function SinglePostSearch(doc_id: string) {

  const [doc, setDocData] = useState<any | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.query(
        Prismic.Predicates.at('my.blog.uid', doc_id),
        { lang : '*' }
      )
      if (response) {
        setDocData(response.results);
      }
    }
    fetchData()
  }, [])


  return (
    <>
      {
        doc ? (
          doc.map(
            (headers: any, i: any) => {
              return <>
                <AccordionItem pb="2" border="none" >
                  {({ isExpanded }) => (
                    <>
                      <CustomBox borderWidth="thin" borderRadius="md" bg="whiteAlpha.50" >
                        <Heading fontSize="2xl">
                          <AccordionButton
                            fontWeight={'semibold'}
                            letterSpacing={"0.5px"}
                            p="4"
                          >
                            <Grid gap={3}>
                              <GridItem colSpan={1}>
                                <Text textAlign="left"
                                  fontSize={{ base: 'sm', sm: 'sm', md: '2xl', lg: '2xl' }}
                                >
                                  <RichText render={([headers.data.title[0]])} />
                                </Text>
                              </GridItem>

                              <GridItem>
                                <Text textAlign="left" fontSize={{ base: 'xs', sm: 'xs', md: 'lg', lg: 'lg' }} textStyle="mono">
                                  {Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' })
                                    .format(Date(headers.data.date))
                                    .toString()
                                  } |
                                <Text fontSize={{ base: 'xs', sm: 'xs', md: 'lg', lg: 'lg' }} textStyle="mono" fontStyle ="oblique" as={Link} to={`/blog/${headers.uid}`}>&nbsp;Permalink</Text>
                                 </Text>

                              </GridItem>
                            </Grid>
                          </AccordionButton>
                        </Heading>
                        <AccordionPanel>
                          <CustomDivider mt="-2" orientation="horizontal" />
                          {headers.tags.map(
                            (tag: any) => {
                              return <><Code colorScheme="gray" mt={1} children={tag} />&nbsp;</>
                            }
                          )}
                          {isExpanded ? (
                            <>
                                {headers.data.content.map(
                                  (posts: any) => {

                                    return <>
                                      <Box textAlign="justify" >
                                        <Text mt="2" mb={2}>
                                          {
                                            posts.type === "preformatted" ?
                                              <Box mb="2" pb="2"
                                                maxW={{ base: "80vw", sm: "80vw", md: "72vw", lg: "72vw" }}>
                                                <CodeBlock
                                                  language="python"
                                                  wrapLines={true}
                                                  theme={atomOneDark}
                                                  text={RichText.asText([posts])}
                                                  customStyle={{
                                                    height: "100%",
                                                    overflow: 'auto',
                                                    fontFamily: 'Fira Code',

                                                  }}
                                                />
                                              </Box>
                                              :
                                              <Box 
                                                maxW={{ base: "80vw", sm: "80vw", md: "72vw", lg: "72vw" }}>
                                                {RichText.render([posts])}
                                              </Box>
                                          }

                                        </Text>
                                      </Box>
                                    </>
                                  }
                                )}
                            </>
                          ) : (
                            ''
                          )}
                        </AccordionPanel>
                      </CustomBox>
                    </>
                  )}
                </AccordionItem>
              </>
            },
          )
        ) : <Text textStyle="mono" p="2">Loading..</Text>
      }
    </>
  )
}


type Props = {
  id: string;
}


const SinglePost = ({ match }: RouteComponentProps<Props>) => {
  return (
    <>
      <Stack
        as={Box}
      >
        <Text color={useColorModeValue('black', 'brand.text')}>
          <Text>
            <Accordion allowMultiple  defaultIndex={[0]}>
              {SinglePostSearch(match.params.id)}
            </Accordion>
          </Text>
        </Text>
      </Stack>
    </>
  );
}

export default SinglePost;