import {
  ChakraProvider,
  Container,
  Stack,
  Box,
  Text,
  Flex,
  Spacer,
  Heading,
  useColorModeValue

} from '@chakra-ui/react';
import NavigationBar from './components/navigationBar.tsx';
// import LandingPage from './components/landingPage.tsx';
import customTheme from './themes/styles.tsx';
import "focus-visible/dist/focus-visible";
import "@fontsource/montserrat/700.css"
import "@fontsource/fira-code";
import { Route, Switch, Link } from "react-router-dom";
import BlogPage from './components/blog.tsx';
import ResumeModal from './components/resume.tsx';
import WebStack from './components/webStack.tsx';
import SinglePost from './components/blogPost.tsx';
import { motion } from 'framer-motion';
import Page from './themes/pageTitle.tsx';
import Buttons from './themes/customButtons';

const _intro = "Hi, my name is";
const _name = "Leslie Callum Lim";

const sentence = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.08,
      delayChildren: 0.5
    },
  },
}

const content = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
}


function App() {
  return (
    <>
      <ChakraProvider theme={customTheme}>
        <NavigationBar />
        <Container centerContent >
          <Stack
            as={Box}
            py={{ base: 20 }}
            minW={{ base: "90vw", sm: "95vw", md: "75vw", lg: "75vw" }}
            // minH={{ base: "70vh", sm: "70vh", md: "80vh", lg: "80vh"}}
            maxW={{ base: "90vw", sm: "95vw", md: "75vw", lg: "75vw" }}
          >
            <motion.div
              variants={sentence}
              initial="hidden"
              animate="visible"
            >
              <Switch>
                <Page exact path="/" title="Home">
                  {/* <LandingPage /> */}

                  <Text textStyle="mono"
                    fontSize={{ lg: '4xl', base: 'xl', sm: 'xl' }}
                  >

                    {_intro.split("").map((char, index) => {
                      return (
                        <motion.span key={char + "-" + index} variants={content}>
                          {char}
                        </motion.span>
                      )
                    })}
                  </Text>
                  <Heading as={'text'}
                    letterSpacing="2px"
                    fontSize={{ lg: '5xl', base: '2xl', md: '2xl', sm: '2xl' }}
                  >
                    {_name.split("").map((char, index) => {
                      return (
                        <motion.span key={char + "-" + index} variants={content}>
                          {char}
                        </motion.span>
                      )
                    })}
                  </Heading>
                  <Flex textAlign="justify">
                    <Stack >
                      <motion.span variants={content}
                        transition={{
                          duration: 2,
                          ease: 'easeOut'
                        }}>
                        <Text pt = "2">
                          I’m someone who enjoys the journey of learning and self betterment.
                          I’m interested and fascinated by a wide array of different hobbies:
                          technology, fitness, nutrition, literature, philosophy, coffee brewing, horology as well as
                          things that would make our lives better! I also enjoy travelling too; because it is truly an
                          eye-opening experience to see how people of different cultures function in their daily aspects of
                          life and learn values that are inculcated within them.
                        </Text>
                      </motion.span>
                      <motion.span variants={content} transition={{
                        duration: 2,
                        ease: 'easeOut'
                      }}>
                        <br />
                        <ResumeModal />
                        <br />
                      </motion.span>
                      <motion.span variants={content} transition={{
                        duration: 2,
                        ease: 'easeOut'
                      }}>
                        <br />
                        <br />
                        <BlogPage />
                      </motion.span>

                      <Flex alignItem="right">
                        <Spacer />
                        <motion.span variants={content} transition={{
                          duration: 2,
                          ease: 'easeOut'
                        }}>
                          <Link to="/blog"><Buttons fontWeight='medium' letterSpacing={1} fontStyle='normal' variant={useColorModeValue('outline', 'ghost')}>Archives</Buttons></Link>
                        </motion.span>
                      </Flex>
                    </Stack>
                  </Flex>
                </Page>
                <Page exact path="/blog" title="Posts"><BlogPage /></Page>
                <Page exact path="/info" title="Info"><WebStack /></Page>
                <Route path={`/blog/:id`} component={SinglePost} />
              </Switch>
            </motion.div>
          </Stack>
        </Container>

      </ChakraProvider>
    </>
  );
}

export default App;
