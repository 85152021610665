import {
  Box,
  Flex,
  Button,
  useDisclosure,
  useColorModeValue,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalCloseButton,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Code,
  Stack,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { useState } from 'react';
import Buttons from '../themes/customButtons';
import CustomTabs from '../themes/customTab';
import Header from '../themes/customHeader';
import CustomDivider from '../themes/customDivider';

export default function ResumeModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isResume, setResume] = useState(true);

  const Resume = () => {
    return (
      <>
        <ModalHeader fontSize="2xl" textStyle="mono" >Experience</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomTabs>
            <TabList textStyle="mono" >
              <Tab _selected={{ color: "white", bg: "yellow.600", fontWeight: "semibold" }}>2019 - 2021</Tab>
              <Tab _selected={{ color: "white", bg: "red.800", fontWeight: "semibold" }}>2017 - 2019</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex textAlign="justify">
                  <Box p="2" >
                    <Header>Data Analyst, Consumer Banking</Header>
                    <CustomDivider mt="2" orientation="horizontal" />
                    <Stack p="2" direction="row">
                      <Code colorScheme="red" children="sas/sql" />
                      <Code colorScheme="green" children="python" />
                      <Code colorScheme="blue" children="excel" />
                    </Stack>
                    <Text p="2">
                      Responsible for data-driven initiatives to improve data pipelines and
                      automation of key business reports.
                      <br />
                      <br />
                      <Text fontWeight="bold">Scope / Initiatives:</Text>
                      • Automated data pipelines for a web-based campaign platform and to
                      a SAS database through Python and SAS. Greater accuracy of the
                      campaign measures was achieved due to zero human input, and on a
                      campaign basis, an average of 5 minutes is saved for each campaign
                      process.
                      <br />
                      <br />
                      • Consumer Analytics: creation of context-specific datamarts to facilitate
                      consistent reporting. Analysis on customer segments and products
                      bankwide.
                      <br />
                      <br />
                      • Campaign Analytics: campaign tracking, leads generation, pre & post
                      campaign evaluation and analysis, creation of data verification pipelines
                      to flag out potential areas of concerns.
                    </Text>
                  </Box>
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex textAlign="justify">
                  <Box p="2">
                    <Header>Data Analyst, Consumer Banking</Header>
                    <CustomDivider mt="2" orientation="horizontal" />
                    <Stack p="2" direction="row">
                      <Code colorScheme="red" children="sas/sql" />
                      <Code colorScheme="green" children="qlikview" />
                      <Code colorScheme="blue" children="excel" />
                    </Stack>
                    <Text p="2">
                      Responsible for the conceptualisation, implementation, and analysis of key
                      business reports across internal business analytics teams.
                      <br />
                      <br />
                      <Text fontWeight="bold">Scope / Initiatives:</Text>
                      • Delivered data-driven analytics by collaborating with various
                      stakeholders across teams and departments to translate business
                      requirements into actionable insights and drive business strategies to
                      empower growth.
                      <br />
                      <br />
                      • Campaign analytics: leads generation, conversion tracking,
                      development of tracking methodologies to accurately measure
                      campaign outcomes and tweak value proposition as needed.
                      <br />
                      <br />
                      • Analysed customer behavioural patterns for commuters identified based
                      on the various modes of e-payments, and strategised ways to drive
                      cashless behaviour with new or existing value propositions.
                    </Text>
                  </Box>
                </Flex>
              </TabPanel>
            </TabPanels>
          </CustomTabs>
        </ModalBody>

        <ModalFooter >
          <Buttons mr="1" onClick={() => setResume(false)}>
            Education
          </Buttons>
          <Buttons onClick={onClose}>
            Close
          </Buttons>
        </ModalFooter>
      </>
    )
  }
  const Education = () => {
    return (
      <>
        <ModalHeader fontSize="2xl" textStyle="mono">Education</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomTabs>
            <TabList textStyle="mono">
              <Tab _selected={{ color: "white", bg: "yellow.600", fontWeight: "semibold" }}>Degree</Tab>
              <Tab _selected={{ color: "white", bg: "red.800", fontWeight: "semibold" }}>Diploma</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex>
                  <Box w="100%">
                    <Header>Bachelors of Computer Science<br />(Big Data) - Distinction</Header>
                    <Grid fontSize={{ base: 'xs', sm: 'md', md: 'lg', lg: 'lg' }} templateColumns="repeat(1, 1fr)" gap={4}>
                      <GridItem letterSpacing={0} pt="1" colspan={6}>University of Wollongong</GridItem>
                      <GridItem letterSpacing={-0.5} pt="1" fontSize={{ base: 'xs', lg: 'lg' }} colStart={7}>2018 - 2020</GridItem>
                    </Grid>
                    <CustomDivider pt="1" orientation="horizontal" />
                  </Box>
                </Flex>
                <Stack pt="2" direction="row" wrap="wrap">
                  <Code colorScheme="red" children="pig" />
                  <Code colorScheme="yellow" children="hive" />
                  <Code colorScheme="orange" children="pyspark" />
                  <Code colorScheme="green" children="hadoop" />
                  <Code colorScheme="red" children="nosql" />
                  <Code colorScheme="teal" children="sql" />
                  <Code colorScheme="blue" children="python" />
                  <Code colorScheme="cyan" children="java" />
                  <Code colorScheme="purple" children="c++" />
                  <Code colorScheme="pink" children="javascript/html" />
                  <Code colorScheme="gray" children="machine learning" />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Flex >
                  <Box w="100%">
                    <Header>Diploma in Business Intelligence & Analytics</Header>
                    <Grid fontSize={{ base: 'xs', sm: 'md', md: 'lg', lg: 'lg' }} templateColumns="repeat(1, 1fr)" gap={4}>
                      <GridItem letterSpacing={0} pt="1" colspan={7}>Temasek Polytechnic</GridItem>
                      <GridItem letterSpacing={-0.5} pt="1" fontSize={{ base: 'xs', lg: 'lg' }} colStart={7}>2012 - 2015</GridItem>
                    </Grid>
                    <CustomDivider pt="1" orientation="horizontal" />
                  </Box>
                </Flex>
                <Stack pt="2" direction="row" wrap="wrap">
                  <Code colorScheme="gray" children="java" />
                  <Code colorScheme="red" children="qlikview" />
                  <Code colorScheme="yellow" children="sas" />
                  <Code colorScheme="blue" children="excel" />
                </Stack>
              </TabPanel>
            </TabPanels>
          </CustomTabs>
        </ModalBody>

        <ModalFooter >
          <Buttons mr="1" onClick={() => setResume(true)}>
            Experience
          </Buttons>
          <Buttons onClick={onClose}>
            Close
          </Buttons>
        </ModalFooter>
      </>
    )
  }


  return (
    <>
      <Button onClick={onOpen}
        fontWeight={'normal'}
        variant={useColorModeValue('outline', 'ghost')}
        _hover={{ bg: useColorModeValue('#ebedf0', '#E4D1C9') }}
        borderColor={useColorModeValue('black', 'gray.700')}
        letterSpacing="2px"
        textStyle="mono"
        fontSize={{ base: 'xs', lg: 'lg' }}
        size="md"
        // p = "2"
        // pb = "-2"
        minW="100%"
        bg={useColorModeValue('whiteAlpha.100', 'brand.accent')}
        textTransform="capitalize"
      color={useColorModeValue('black', 'gray.700')}
      // bg={useColorModeValue('brand.bg', '#e5e5e5')}
      // px={2}
      >
        {/* RESUME */}
        CURRICULUM VITAE
      </Button>

      <Modal isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInRight"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent minW="40%" maxW="65vh" bg={useColorModeValue('#e5e5e5', 'gray.800')}>
          {isResume ? <Resume /> : <Education />}
        </ModalContent>
      </Modal>
    </>
  )
}
