import {
  Text,
  Stack,
  Heading,
  useColorModeValue,
  ListItem,
  List,
  ListIcon,
  Link as ExtLink,
} from '@chakra-ui/react';

import CustomBox from '../themes/customBox';
import CustomDivider from '../themes/customDivider';
import { FiChevronRight, FiCheck } from 'react-icons/fi';

const WebStack = () => {
  return (
    <>
    <Stack spacing={4} >
      <Stack as={CustomBox} borderWidth="thin" borderRadius="md" bg="whiteAlpha.50" maxW={{ base: "100vw", sm: "100vw", md: "80vw", lg: "80vw" }}>
        <Text p="2" color={useColorModeValue('black', 'brand.text')}>
          <Text p = "2">
            <Heading>Current Stack</Heading>
            <CustomDivider p = "1" orientation="horizontal" />
            <List p = "2" spacing={2}>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Frontend Framework: <ExtLink href="https://reactjs.org/docs/create-a-new-react-app.html#create-react-app">create-react-app</ExtLink> 
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                User Interface: <ExtLink href="https://chakra-ui.com/">chakra-ui</ExtLink>
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Headless CMS: <ExtLink href="https://prismic.io/">prismic.io</ExtLink>
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Routing: <ExtLink href="https://github.com/ReactTraining/react-router">react-router</ExtLink>
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Code Block: <ExtLink href="https://github.com/rajinwonderland/react-code-blocks">react-code-blocks</ExtLink>
              </ListItem>
            </List>
          </Text>
        </Text>
      </Stack>
      
      <Stack as={CustomBox} borderWidth="thin" borderRadius="md" bg="whiteAlpha.50" maxW={{ base: "100vw", sm: "100vw", md: "80vw", lg: "80vw" }}>
        <Text p = "2" color={useColorModeValue('black', 'brand.text')}>
          <Text p = "2">
            <Heading>To Do</Heading>
            <CustomDivider p="1" orientation="horizontal" />
            <List p = "2" spacing={2}>
              <ListItem>
                <ListIcon as={FiCheck} color="gray.500"></ListIcon>
                Routing 
              </ListItem>
              <ListItem>
                <ListIcon as={FiCheck} color="gray.500" />
                Code Block 
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Pagination
              </ListItem>
              <ListItem>
                <ListIcon as={FiChevronRight} color="gray.500" />
                Backend Implementation with FastAPI
              </ListItem>
            </List>
          </Text>
        </Text>
      </Stack>
      </Stack>
    </>
  );
}

export default WebStack;