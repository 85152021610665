import { useColorModeValue, Divider, DividerProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

interface Props extends DividerProps {
}

const CustomDivider = ({ children, ...props }: PropsWithChildren<Props>) => (
  <Divider borderColor={useColorModeValue('gray.400', 'gray.700')} {...props} >
    {children}
  </Divider>
)
export default CustomDivider;