import {
  Box,
  Flex,
  HStack,
  Heading,
  IconButton,
  useColorModeValue,

} from '@chakra-ui/react';

import { Link } from "react-router-dom";

import { ColorModeSwitcher } from '../ColorModeSwitcher';
import CopyEmail from '../themes/copyEmail'
import { FiCode } from 'react-icons/fi';
export default function NavigationBar() {
  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Link to="/">
              <Box>
                {/* <IoInformationSharp/> */}
                {/* <Avatar
                bg={useColorModeValue('gray.100', 'gray.100')}
                size={'sm'}
                src={
                  '/images/profile.png'
                }
              /> */}
                <Heading size="md" fontStyle="normal" color={useColorModeValue('gray.900', 'gray.100')}>
                  CL
                </Heading>
              </Box>
            </Link>
          </HStack>
          <Flex alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}>
              <Link to="/info">
                <IconButton
                  size="md"
                  fontSize="lg"
                  aria-label={`Info`}
                  variant="ghost"
                  // color={useColorModeValue('gray.900', 'gray.100')}
                  marginLeft="2"
                  icon={<FiCode />}
                />
              </Link>
              <CopyEmail />
              <ColorModeSwitcher justifySelf="flex-end" />
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}