import { extendTheme, ThemeConfig } from "@chakra-ui/react"
import { mode, Styles } from "@chakra-ui/theme-tools"

const config : ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}

const fonts = {
  // body: "Lato, -apple-system",
  heading: "Montserrat, -apple-system",
}

const styles: Styles = {
  global: (props) => ({
    body: {
      fontFamily: "body",
      color: mode("gray.800", "gray.300")(props),
      bg: mode("#e5e5e5", "gray.800")(props),
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base",
    },
    shadows: {
      // outline: '0 0 0 3px rgba(99,164,79,.4)'
    },
    "strong" : {
          fontWeight: "bold",
          // color: mode("brand.bg", "#936639"),
          fontSize:{
            base: 'xs', 
            sm: 'sm', 
            md: 'sm' ,
            lg: 'md' 
          },
        },
    "a" : {
      // textDecoration: "underline" ,
          color: mode("gray.900", "#e2cfc4")(props),
          // fontWeight: 'bold',
          // font: "Montserrat",
          // letterSpacing: "1.1px",
          textStyle:'mono',
          // fontStyle: 'oblique'
    },
    "img": {
      borderRadius: "3px"
    },
    "pre" : {
      // wordWrap: "break-word",
      // overflowX: "auto",
      // whiteSpace: "pre-wrap",
      // overflowY: "auto",
      // minWidth: "100%"
    },

    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
  }),
}


const customTheme = extendTheme({
  styles,
  fonts,
  colors: {
    brand: {
      accent: '#e2cfc4',
      text: 'gray.300',
      bg: 'gray.800',
    }
  },
  config,
  // styles: {
  //   global: {
  //   "strong": {
  //     fontWeight: "bold",
  //     color: "teal.600",
  //     fontSize:{
  //       base: 'xs', 
  //       sm: 'sm', 
  //       md: 'sm' ,
  //       lg: 'md' 
  //     },
  //   },
  //   },
  // },
  textStyles: {
    mono: {
      fontFamily: 'monospace'	
    },
  },
  components: {
    Text: {
      baseStyle: {
        fontSize:{
          base: 'xs', 
          sm: 'sm', 
          md: 'sm' ,
          lg: 'md' 
        },
      },
    },
    
    Container: {
      baseStyle: {
        // minW: "40%",
        // maxW: "65vh"
      }
    },
    Stack: {
        baseStyle: {
          // align: 'flex-start',
          // alignSelf: 'left',
          // position: 'relative'
        },  
    },
    Button: {
        baseStyle: {
          size:{
            base: 'xs', 
            sm: 'xl', 
            md: 'xl' ,
            lg: '2xl' 
          },
          borderRadius: 'md',
          variant: "outline",
          // colorScheme:'gray'
          // buttonVariant: 'outline',
          // color: mode('gray.700', 'gray.700')
          // borderWidth: '0.5px',
          // borderColor: mode('brand.bg', 'brand.accent')
        },  
    },
    Heading: {
        baseStyle: {
          fontSize:{
            base: 'sm', 
            sm: 'md', 
            md: 'xl' ,
            lg: 'xl' 
          },
          fontWeight: 'semibold',
          letterSpacing: '0.5px',
          variant:"ghost",
          color: mode('brand.bg', 'brand.accent')
        },
    },
    Code: {
      baseStyle: {
        fontSize:{
          base: 'xs', 
        },
      },
    },
    Link: {
      baseStyle: {
        // fontWeight: 'bold',
        color: mode('brand.bg', '#936639')
      }
    },
    Box: {
      baseStyle: {
        // textAlign: 'justify'
      borderColor: mode('gray.400', 'gray.700')
      },
    },
    Divider: {
      baseStyle: {
        // textAlign: 'justify'
      borderColor: mode('red.800', 'red.800')
      },
    },
  },
  ListIcon: {
    baseStyle: {
      // offsetY: "-2px"
    }
  },
});

export default customTheme;